/* Admin Products Design */
#admin_products {
    min-height: 100vh;
    background-color: #0f0f0f;
    padding: 50px 30px 30px;
}

.products {
	min-height: 100vh;
	text-align: center;
	padding: 50px 0px 0px;
}

.products > div:first-of-type {
	padding: 40px 0px 10px;
}

.products > div > a {
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
    font-size: 45px;
    text-align: center;
    padding: 0px 13px;
    border-radius: 50%;
    float: right;
	margin: -50px 0px;
	transition: all 0.2s;
}

.products > div > a:hover {
	color: #000;
	background: #f0f0f0;
	border: 1px solid #f0f0f0;
}

@media(max-width: 590px) {
	.products > div:first-of-type {
		padding: 10px;
		margin-bottom: 20px;
	}

	.products > div a {
		font-size: 35px;
		float: none;
		margin: 0;
		padding: 0px 10px;
	}
}

.products > select {
    padding: 10px;
    font-size: 18px;
    color: #fff;
    background: transparent;
    border-bottom: 1px solid #CECECE;
    border-top: none;
    border-right: none;
    border-left: none;
	outline-style: none;
	text-align: center;
	margin: 0px 10px 20px;
    display: inline-block;
}

.products > select option {
    color: #000;
}

.products > div {
	padding: 50px 0px 50px;
	margin: 0px auto 0px;
}

/* Optimized Product Item Display Design for Desktop */
.productItem {
    margin: 10px;
	padding: 0px;
	max-width: 300px;
	transition: 0.5s;
	background: #2b2b2b;
	outline: none;
    border: none;
    display: inline-block;
}

.productItem img {
	object-fit: cover;
	background: #a8a8a8;
	transition: all 0.5s ease;
}

.productItem:hover img {
	opacity: 0.5;
    transition: all 0.5s ease;
}

.productItem_description {
	padding: 0px 10px 0px;
	background-color: #2b2b2b;
	margin: -5px 0px 0px;
	color: #fff;
}

.productItem_description h1 {
	font-size: 18px;
	margin: 10px;
}

/* Optimized Product Item Display Design for Tablets and Phones */
@media(max-width: 1480px) {
	.productItem { 
		margin: 5px; 
	}
}

@media(max-width: 1440px) {
	.productItem {
		max-width: 300px;
	}
}

@media(max-width: 690px) {
	.productItem {
		max-width: 250px;
	}
}

@media(max-width: 590px) {	
    #admin_products {
        padding: 50px 5px 30px;
    }
	.productItem {
		min-width: 270px;
	}
}