/* Shop Design */
#shop {
	background: #000;
	min-height: 100vh;
	padding: 80px 0px 0px;
	text-align: center;
}

/* Shop Header */
.productCategoryPage_header {
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	min-height: 150px;
	text-align: center;
	padding: 50px 0px 20px;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.productCategoryPage_header p {
	color: #fff;
	font-size: 35px;
	border: 1px solid #fff;
	width: 370px;
	margin: 50px auto 100px;
	padding: 20px;
}

.productCategoryPage_header a {
	font-size: 20px;
	color: #fff;
}

.productCategoryPage_header > a div {
	width: 200px;
	display: inline-block;
	margin: 0px 50px 0px;
	padding: 10px;
	border-top: 1px solid #fff;
	border-bottom: 1px solid #fff;
	border-right: none;
	border-left: none;
	z-index: 1;
	background: transparent;
	transition: all 0.3s ease;
  	position: relative;
}

.productCategoryPage_header > a div:hover {
	color: #000;
}

.productCategoryPage_header > a div:hover:after {
	top: 0;
	height: 100%;
}

.productCategoryPage_header > a div:after {
	position: absolute;
	content: "";
	width: 100%;
	height: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	background: #f1f1f1;
	transition: all 0.3s ease;
}

/* Responsive Shop Header */
@media(max-width: 650px) {
	.productCategoryPage_header > a div {
		width: 200px;
		margin: 30px 20px 0px;
	}
}

@media(max-width: 500px) {
	.productCategoryPage_header p {
		font-size: 25px;
		width: 250px;
		padding: 15px;
	}

	.productCategoryPage_header > a div { 
		width: 180px;
	}
}

/* Different Product Page Headers */
#allProducts_header {
	background-image: url('/images/TEST_AllProductsHeader.jpg');
}

#maskProducts_header {
	background-image: url('/images/TEST_MaskHeader.jpg');
}

#shirtProducts_header {
	background-image: url('/images/TEST_ShirtHeader.jpg');
}

/* Optimized Product Display Design for Desktop */
.productsDisplay {
	background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-image: url('/images/TEST_ShopBG.jpg');
	min-height: 100vh;
	text-align: center;
	padding: 50px 0px 0px;
	border-top: 5px solid #000;
	border-right: none;
	border-left: none;
	border-bottom: none;
}

.productsDisplay > div {
	padding: 50px 0px 50px;
	margin: 0px auto 0px;
}

.shopItem {
	margin: 10px;
	padding: 0px;
	max-width: 350px;
	transition: 0.5s;
	background: #1a1a1a;
	outline: none;
	border: none;
}

.shopItem img {
	object-fit: cover;
	background: #818181;
	transition: all 0.5s ease;
}

.shopItem:hover img {
	opacity: 0.5;
	transition: all 0.5s ease;
}

.shopItem_description {
	padding: 0px 10px 0px;
	background-color: #1a1a1a;
	margin: -4px 0px 0px;
	color: #fff;
}

.shopItem_description h1 {
	font-size: 18px;
	margin: 10px;
}

.shopItem_description p {
	font-size: 15px;
	font-weight: bold;
	margin: 10px;
}

/* Optimized Product Display Design for Tablets and Phones */
@media(max-width: 1480px) {
	.shopItem { 
		margin: 5px; 
	}
}

@media(max-width: 1440px) {
	.shopItem {
		max-width: 300px;
	}
}

@media(max-width: 630px) {
	.shopItem {
		max-width: 250px;
	}
}

@media(max-width: 520px) {
	.shopItem {
		min-width: 270px;
	}
}

/* Individual Shop Item Pages */
#items {
	min-height: 100vh;
	background: #1a1a1a;
	text-align: center;
	padding: 80px 50px 20px;
}

#items a {
	color: #fff;
	width: 40px;
	font-size: 40px;
	display: block;
	margin: 0px 0px 20px;
	padding: 10px;
  	position: relative;
}

.individualItem > .individualItem_photos {
	width: 550px;
	margin: 40px 0px 0px 50px;
	float: left;
	background: #818181;
}

.individualItem > .orderItem {
	color: #fff;
	text-align: left;
	font-size: 20px;
	display: inline-block;
}

.orderItem > div:nth-of-type(2) button {
	width: 43px;
	height: 43px;
	margin-right: 10px;
	background: #fff;
	border: none;
	transition: all 0.5s ease;
	font-size: 16px;
}

.orderItem > div:nth-of-type(2) button:hover {
	background: #a7a6a6;
	transition: all 0.5s ease;
}

.orderItem > div:nth-of-type(3) button {
	width: 35px;
	height: 35px;
	margin-right: 10px;
	outline: none;
	border-radius: 50%;
	border: none;
}

.orderItem > div:nth-of-type(4) select {
	border: none;
	width: 200px;
	height: 40px;
	color: #000;
	outline: none;
	font-size: 20px;
}

.orderItem > button {
	text-transform: uppercase;
	background: #000;
	font-size: 18px;
	color: #fff;
	padding: 15px 100px 15px;
	margin-top: 50px;
	transition: all 0.4s ease;
	letter-spacing: 2px;
	border: none;
}

.orderItem > button:hover {
	background: #fff;
	transition: all 0.4s ease;
	color: #000;
}

/* Responsive Individual Shop Item Pages */
@media(max-width: 1125px) {
	.individualItem > .individualItem_photos {
		width: 450px;
		margin-top: 100px;
	}

	.orderItem {
		font-size: 18px;
	}

	.orderItem > div:nth-of-type(3) select {
		width: 150px;
		height: 35px;
		font-size: 18px;
	}

	.orderItem > button {
		font-size: 15px;
		padding: 15px 80px 15px;
	}
}

@media(max-width: 1013px) {
	#items {
		padding: 80px 20px 20px;
	}

	.individualItem > .individualItem_photos {
		width: 420px;
		float: none;
		margin: 0px auto;
	}

	.individualItem img {
		width: 420px;
	}

	.orderItem {
		display: block;
		text-align: center;
	}

	.orderItem > button {
		font-size: 15px;
		padding: 15px 80px 15px;
		margin: 50px 0px 50px;
	}
}

@media(max-width: 500px) {
	.individualItem > .individualItem_photos,
	.individualItem img {
		width: 300px;
	}

	.orderItem h1 {
		font-size: 22px;
	}

	.orderItem > button {
		padding: 15px 60px 15px;
	}
}

@media(max-width: 350px) {
	#items {
		padding: 80px 10px 20px;
	}

	.individualItem > .individualItem_photos {
		width: 300px;
	}

	.individualItem > .individualItem_photos,
	.individualItem img {
		width: 280px;
	}
}