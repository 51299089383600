/* Admin Product Edit Design */
#admin_editProduct {
    min-height: 100vh;
    background-color: #0f0f0f;
    padding: 80px 20px 100px;
}

#editProduct {
    border: 1px solid #fff;
    margin: 50px auto 20px;
    font-size: 20px;
}

#editProduct h3 {
    margin-bottom: 50px;
}

#editProduct > button:last-of-type {
    background: #881212;
}

#editProduct > button:last-of-type:hover {
    background: #570404;
    color: #fff;
}

.currentDetails {
    max-width: 600px;
    text-align: center;
    min-height: 400px;
    margin: 50px auto 50px;
    color: #fff;
    padding: 20px;
    font-size: 20px;
    border: 1px solid #fff;
}

.currentDetails div {
    margin: 0px auto 15px;
    padding: 20px;
}

.currentDetails div > div > p {
    display: inline;
    margin: 0px 10px 0px;
}

.currentDetails img {
    width: 200px;
    display: inline-block;
}

/* Responsive Admin Product Edit Design */
@media(max-width: 440px) {
    #editProduct div:first-of-type button {
        font-size: 18px;
        width: 45px;
        height: 35px;
        margin: 0px 5px 20px;
    }
}

@media(max-width: 420px) {
    #editProduct {
        padding: 20px 0px 20px;
    }

    #editProduct input,
    #editProduct select {
        width: 200px;
    }

    #editProduct select {
        width: 220px;
    }
}