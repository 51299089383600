/* Global */
html { 
	scroll-behavior: smooth; 
}

body {
	margin: 0;
	padding: 0;
	font-family: sans-serif;
}

ul, li {
	list-style: none;
	padding: 0;
	margin: 0;
}

a { 
	text-decoration: none; 
}

button {
	outline: none;
}

.fileInput::-webkit-file-upload-button {
    visibility: hidden;
}

.fileInput::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
}

.fileInput:hover::before {
    border-color: black;
}

.fileInput:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

/* Animations */
.fade-in { 
	animation: fadeIn 2s; 
}

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

a svg,
a svg rect {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    fill: transparent;
}

a svg rect {
    stroke: #94959c;
    stroke-width: 4;
    transition: 0.8s;
    stroke-dasharray: 90, 220;
    stroke-dashoffset: 75;
}

a:hover svg rect {
    stroke-dasharray: 30, 280;
    stroke-dashoffset: 325;
}

a:hover svg rect:nth-child(even) {
    stroke-dasharray: 30, 280;
    stroke-dashoffset: 695;
}

/* Scrollbar */
html::-webkit-scrollbar { width: 8px; }
html::-webkit-scrollbar-thumb { background: #a1a1a1; }