/* Cart Design */
#cart {
    min-height: 100vh;
    background: #0f0f0f;
    text-align: center;
    padding: 120px 50px 0px;
    background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-image: url('/images/TEST_ShopBG.jpg');
}

/* Cart Carousel */
.mobileCartView { display: none; }

.cartContent {
    min-height: 460px;
    border: 2px solid #fff;
    margin: 0px auto 50px;
    background-attachment: fixed;
	background-position: center;
	background-size: cover;
	background-image: url('/images/TEST_AllProductsHeader.jpg');
}

#cart > div button {
    background: transparent;
    z-index: 1;
}

#cart > div button:first-of-type {
    left: 0;
}

#cart > div button:last-of-type {
    right: 0;
}

/* Cart Items */
.cartItem {
    position: relative;
    width: 300px;
    height: 350px;
    background: #000;
    display: inline-block;
    text-align: center;
    overflow: none;
}

.cartItemClose {
    color: #646464;
	font-size: 40px;
	font-weight: bold;
    right: 0;
    margin: 0px 10px 0px 0px;
    transition: all 0.4s ease;
    z-index: 2;
    position: absolute;
}
  
.cartItemClose:hover,
.cartItemClose:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
	transform: rotate(90deg);
	transition: all 0.4s ease;
}

.cartItemImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cartItemImage img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
    z-index: 1;
    background: #9c9c9c;
}

.cartItemContent {
    position: absolute;
    bottom: 5px;
    left: 20%;
    width: 60%;
    height: 50px;
    background: #000;
    color: #fff;
    transition: 0.5s;
    overflow: hidden;
    padding: 12px;
    box-sizing: border-box;
    z-index: 3;
}

.cartItemContent > p {
    margin: 5px;
    color: #acacac; 
}

.cartItemContent div {
    margin: 100px 0 0;
    padding: 0;
    opacity: 0;
    transition: 0.5s;
    text-align: center;
}

.cartItem .cartItemContent:hover {
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.9);
}

.cartItem .cartItemContent:hover img { 
    opacity: 0; 
}

.cartItem .cartItemContent:hover > p {
    opacity: 0;
    display: none;
    transition: 0.5s;
}

.cartItem .cartItemContent:hover div {
    opacity: 1;
    transition-delay: 0.5s;
}

.cartItemDetails {
    background: #000;
    margin-top: 116%;
    color: #fff;
    padding: 1px 5px 1px;
}

.cartItemDetails > p:first-of-type {
    font-size: 19px;
    margin: 10px 5px 10px;
}

.cartItemDetails > p:last-of-type {
    font-size: 17px;
    margin: 0px 5px 10px;
}

/* Responsive Cart Carousel & Cart Items */
@media(max-width: 1200px) {
    #cart {
        padding: 120px 10px 0px;
    }

    .desktopCartView { display: none; }
    .mobileCartView { display: block; }

    .mobileCartView > p {
        color: #fff;
        font-size: 17px;
        letter-spacing: 2px;
        text-transform: uppercase;
    }

    .cartItem {
        width: 100%;
        height: 100px;
        margin: 5px 0px 0px;
    }

    .cartItemClose:hover {
        color: #fff;
    }

    .cartItemImage img {
        max-width: 150px;
    }

    .cartItemContent {
        border-bottom: 1px solid #fff;
        border-top: none;
        border-right: none;
        border-left: none;
        margin: 0px 45% 0px;
        width: 150px;
        bottom: 30px;
    }

    .cartItemContent > p {
        color: #fff;
    }

    .cartItemContent > div {
        margin: 5px 0px 0px;
    }

    .cartItemContent > div p {
        margin: 0px 0px 10px;
    }

    .cartItem .cartItemContent:hover {
        width: 100%;
        height: 100%;
        margin: 0;
        background: rgba(0, 0, 0, 0.95);
        border: none;
    }

    .cartItemDetails {
        margin: 25px 30% 0px;
        color: #fff;
        padding: 0;
        width: 150px;
    }
}

@media(max-width: 650px) {
    .cartItem .cartItemContent {
        margin: 0px 42% 0px;
    }
}

@media(max-width: 570px) {
    .cartItemClose {
        font-size: 35px;
    }

    .cartItem .cartItemContent {
        background: transparent;
        margin: 0px 28% -35px;
        border: none;
        position: absolute;
    }

    .cartItem .cartItemContent > p {
        font-size: 15px;
        text-decoration: underline;
    }

    .cartItemDetails {
        margin: 0px 48% 0px;
        position: absolute;
        bottom: 30px;
    }

    .cartItemDetails > p:first-of-type {
        font-size: 17px;
    }
    
    .cartItemDetails > p:last-of-type {
        font-size: 15px;
    }
}

@media(max-width: 400px) {
    .cartItemImage img {
        max-width: 120px;
    }

    .cartItem .cartItemContent {
        margin: 0px 21% -35px;
    }

    .cartItemDetails {
        margin: 0px 40% 0px;
    }
}

/* Continue Shopping & Checkout Buttons */
.cartOptionButtons {
    min-height: 170px;
    padding-bottom: 100px;
    margin-top: 50px;
}

.cartOptionButtons a {
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: absolute;
    width: 253px;
    height: 60px;
    left: 50%;
    transform: translate(-50%);
    line-height: 60px;
}

.cartOptionButtons a:nth-of-type(2) {
    margin: 100px 0px 0px;
}