/* Home Page Design */
#home {
	background-color: #000;
	min-height: 100vh;
	text-align: center;
}

.homeLogo {
	padding: 5px 0px 0px;
	margin: 0px auto 0px;
}

.homeLogo img {
	height: 500px;
}

.homeTitle h1 {
	color: #fff;
	animation: desktop_type 2s steps(25);
	overflow: hidden;
	white-space: nowrap;
	font-family: consolas;
	border-right: 2px solid #fff;
	width: 25ch;
	font-size: 45px;
	margin: 0px auto 0px;
}

@keyframes desktop_type {
	0% { width: 0ch; }
	100% { width: 25ch; }
}

/* About Page Design */
#about:before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: -1;
	display: block;
	background-image: url('/images/TEST_AboutBG.jpg');
	background-position: center;
	background-size: cover;
	width: 100%;
	height: 100%;
}

#about {
	background-color: rgba(0, 0, 0, 0.3);
	min-height: 530px;
	text-align: center;
	padding-top: 50px;
	padding-bottom: 50px;
}

.aboutTitle {
	padding: 100px 50px 0px;
}

.aboutTitle h1 {
	margin: 0;
	font-size: 50px;
	color: #fff;
}

.aboutDescription {
	max-width: 700px;
	margin: 70px auto 20px;
	padding: 10px 50px 10px;
	color: #fff;
	font-size: 25px;
}

/* Responsive Home & About Pages */
@media(max-width: 720px) {
	.homeTitle h1 { 
		font-size: 35px; 
	}
}

@media(max-width: 510px) {
	.homeLogo { 
		padding-top: 20px; 
	}

	.homeLogo img { 
		height: 400px; 
	}

	.homeTitle { 
		margin-left: 10px;
	}

	.homeTitle h1 {
		border: none;
		margin: 0px auto 20px;
		width: 9ch;
	}

	.homeTitle h1 span {
		animation-delay: 1s;
		animation: mobile_type 1s steps(9);
		display: block;
		margin: 0px auto 20px;
	}

	@keyframes mobile_type {
		0% { width: 0ch; }
		100% { width: 9ch; }
	}
}

@media(max-width: 500px) {
	.aboutTitle h1 { 
		font-size: 40px; 
	}

	.aboutDescription { 
		font-size: 20px; 
	}
}

@media(max-width: 400px) {
	.homeLogo { 
		padding-top: 30px; 
	}

	.homeLogo img { 
		height: 300px; 
	}
}